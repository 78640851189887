
import React from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'
import Moment from "moment"
import Img from "gatsby-image"
import Button from "../components/button"

export const query = graphql`
query staffTemplateQuery($uid: String, $id: String) {
  prismic {
    allStaffs(uid: $uid) {
      edges {
        node {
          first_name
          role
          profile_photo
            profile_photoSharp{
                childImageSharp {
                    fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
            }
          _meta {
            uid
          }
          bio
          email
          major
          name
        }
      }
    }
    allPosts(where: {author: $id}) {
      edges {
        node {
          post_body
          title
          _meta {
            firstPublicationDate
            type
            uid
          }
          author {
            ... on PRISMIC_Staff {
              first_name
              _meta {
                type
                id
                uid
              }
            }
          }
        }
      }
    }
  }
}
`

export default (props) => {
  const doc = props.data.prismic.allStaffs.edges[0];
  const posts = props.data.prismic.allPosts.edges;
  if (!doc) return null;
  if (!posts) return null;
  return (
    <div className="body">
      <Layout>
        <SEO title={doc.node.first_name[0].text} />
        <div data-w-id="63b8498e-1639-aebb-9ce4-f79485e68895" className="page">
          <section className="section">
            <div className="preview template">
              <div className="card-image staff">
                <Img fluid={doc.node.profile_photoSharp.childImageSharp.fluid} alt="Profile" />
              </div>
              <div className="preview">
                <div className="text-link">
                  <h1 className="heading">{doc.node.name[0].text}<br /></h1>
                </div>
                <div className="post-details"><h4>{doc.node.role[0].text}</h4>
                </div>
                <p>{doc.node.bio && doc.node.bio[0] && doc.node.bio[0].text}<a href="mailto:ellie.shackelford@my.wheaton.edu"><br /></a></p>
                <div className="button-parent page">
                  <Button name={'Email ' + doc.node.first_name[0].text} to={'mailto:' + doc.node.email[0].text} />
                  <Link to="/staff/">
                    <div className="button w-inline-block">Back to Staff</div>
                  </Link>
                </div>
              </div>
            </div>
            <h4>{doc.node.first_name[0].text}&#x27;s Blog Posts:<br /></h4>
            <div>
              {posts.map(({ node: post }, i) => (
                <div key={i} className="preview">
                  <Link to={linkResolver(post._meta)}>
                    <div className="text-link w-inline-block">
                      <h2 className="heading">{post.title[0].text}</h2>
                    </div>
                  </Link>
                  <p>{post.post_body[0].text + ".."}</p>
                  <div className="post-details">
                    {Moment(Date.parse(post._meta.firstPublicationDate)).format("LL")}</div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </Layout>
    </div >
  )
}

